import { doc, getDoc } from "firebase/firestore";
import { DocumentData, FirestoreDataConverter } from "@firebase/firestore";
import { firestore } from "../../fbConfig";
import { IUSER } from "../types";

interface GETCOACHPARAMS {
  uid: string;
}

export const getCoachDetails = async ({ uid }: GETCOACHPARAMS) => {
  const usersRef = doc(firestore, "users", uid).withConverter(
    userDataConverter
  );

  try {
    const docSnap = await getDoc(usersRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.log("No such document!");
      return null;
    }
  } catch (error) {
    console.log("Error", error);
    return null;
  }
};

export const userDataConverter: FirestoreDataConverter<IUSER> = {
  // change our model to how it is stored in Firestore
  toFirestore(model) {
    // in this case, we don't need to change anything and can
    // let Firestore handle it.
    const data = { ...model } as DocumentData; // take a shallow mutable copy

    // But for the sake of an example, this is where you would build any
    // values to query against that can't be handled by a Firestore index.
    // Upon being written to the database, you could automatically
    // calculate a `discountPercent` field to query against. (like "what
    // products have a 10% discount or more?")
    // if (data.offer) {
    //   data.discountPercent = Math.round(100 - (model.discountedPrice * 100 / model.regularPrice))) / 100; // % accurate to 2 decimal places
    // } else {
    //   data.discountPercent = 0; // no discount
    // }
    return data;
  },

  // change Firestore data to our model - this method will be skipped
  // for non-existant data, so checking if it exists first is not needed
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options)!; // DocumentData
    // because ListingsModel is not a class, we can mutate data to match it
    // and then tell typescript that it is now to be treated as ListingsModel.
    // You could also specify default values for missing fields here.
    //data.timestamp = (data.timestamp as Timestamp).toDate(); // note: JS dates are only precise to milliseconds
    // remove the discountPercent field stored in Firestore that isn't part
    // of the local model
    //delete data.discountPercent;
    return data as IUSER;
  },
};
