import { Fragment, useEffect, useState } from "react";
import { ListItem, List, Button, Stack, TextField } from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import { CircularProgress } from "@mui/material";
import {
  IDESCRIPTION,
  LANGUAGES,
  PROFILE_DESCRIPTION_TYPES,
  TDETAILS,
} from "../../util/types";
import IconButton from "@mui/material/IconButton";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import LanguageToggler from "../LanguageToggler/LanguageToggler";
import { useAuthState } from "../../context/AuthContext";
import { firestore } from "../../fbConfig";
import { CollectionNames } from "../../util/collections";
import { toast } from "react-toastify";
type textFieldProps = {
  text: {
    en: string;
    sv: string;
  };
  edit: boolean;
};

const ExpertProfile = () => {
  const { user } = useAuthState();
  const [language, setLanguage] = useState<"en" | "sv">("en");
  const [loader, setLoader] = useState(false);

  const [approachDescription, setApproach] = useState<textFieldProps>({
    text: {
      en: "",
      sv: "",
    },
    edit: false,
  });

  const [aboutDescription, setAbout] = useState<textFieldProps>({
    text: {
      en: "",
      sv: "",
    },
    edit: false,
  });

  const [expectDescription, setExpect] = useState<textFieldProps>({
    text: {
      en: "",
      sv: "",
    },
    edit: false,
  });

  useEffect(() => {
    if (
      user?.coachData?.description &&
      user?.coachData?.description.length > 0
    ) {
      setAbout({
        edit: false,
        text: gatherMatchDescription(
          user?.coachData?.description,
          PROFILE_DESCRIPTION_TYPES.ABOUT
        ),
      });

      setExpect({
        edit: false,
        text: gatherMatchDescription(
          user?.coachData?.description,
          PROFILE_DESCRIPTION_TYPES.WHAT_TO_EXPECT
        ),
      });

      setApproach({
        edit: false,
        text: gatherMatchDescription(
          user?.coachData?.description,
          PROFILE_DESCRIPTION_TYPES.MY_APPROACH
        ),
      });
    }
  }, [user?.coachData?.description]);

  const gatherMatchDescription = (
    description: IDESCRIPTION[],
    type: PROFILE_DESCRIPTION_TYPES
  ) => {
    let full_des = {
      en: "",
      sv: "",
    };

    const matchedDes = description.filter((ele) => {
      return ele.type === type;
    });

    matchedDes[0].details.map((value: TDETAILS, index) => {
      if (index === 0) {
        full_des.en += value.en;
        full_des.sv += value.sv;
      } else {
        full_des.en = full_des.en + value.en;
        full_des.sv = full_des.sv + value.sv;
      }
    });
    return full_des;
  };

  const inputs = [
    {
      id: 1,
      name: "About",
      required: true,
      type: PROFILE_DESCRIPTION_TYPES.ABOUT,
    },
    {
      id: 2,
      name: "My approach",
      required: true,
      type: PROFILE_DESCRIPTION_TYPES.MY_APPROACH,
    },
    {
      id: 3,
      name: "What to expect",
      required: true,
      type: PROFILE_DESCRIPTION_TYPES.WHAT_TO_EXPECT,
    },
  ];

  const langugeHandler = (value: LANGUAGES) => {
    setLanguage(value);
  };
  const setFieldValue = (value: string, type: PROFILE_DESCRIPTION_TYPES) => {
    switch (type) {
      case PROFILE_DESCRIPTION_TYPES.MY_APPROACH:
        setApproach({
          edit: approachDescription.edit,
          text: {
            en:
              language === LANGUAGES.ENGLISH
                ? value
                : approachDescription.text.en,
            sv:
              language === LANGUAGES.SWEDISH
                ? value
                : approachDescription.text.sv,
          },
        });
        break;
      case PROFILE_DESCRIPTION_TYPES.ABOUT:
        setAbout({
          edit: aboutDescription.edit,
          text: {
            en:
              language === LANGUAGES.ENGLISH ? value : aboutDescription.text.en,
            sv:
              language === LANGUAGES.SWEDISH ? value : aboutDescription.text.sv,
          },
        });
        break;
      case PROFILE_DESCRIPTION_TYPES.WHAT_TO_EXPECT:
        setExpect({
          edit: expectDescription.edit,
          text: {
            en:
              language === LANGUAGES.ENGLISH
                ? value
                : expectDescription.text.en,
            sv:
              language === LANGUAGES.SWEDISH
                ? value
                : expectDescription.text.sv,
          },
        });
        break;
      default:
    }
  };

  const setDetailsToDB = async () => {
    setLoader(true);
    let engAbout = aboutDescription.text.en;
    let sweAbout = aboutDescription.text.sv;
    let engExpect = expectDescription.text.en;
    let sweExpect = expectDescription.text.sv;
    let engAppro = approachDescription.text.en;
    let sweAppro = approachDescription.text.sv;

    const aboutDetailsArray = [
      {
        en: engAbout,
        sv: sweAbout,
      },
    ];

    const expectDetailsArray = [
      {
        en: engExpect,
        sv: sweExpect,
      },
    ];

    const ApproachDetailsArray = [
      {
        en: engAppro,
        sv: sweAppro,
      },
    ];

    const tmp: any = { ...user?.coachData };

    tmp.description.forEach((ele: IDESCRIPTION, index: number) => {
      if (ele.type === PROFILE_DESCRIPTION_TYPES.ABOUT) {
        tmp.description[index].details = aboutDetailsArray;
      } else if (ele.type === PROFILE_DESCRIPTION_TYPES.MY_APPROACH) {
        tmp.description[index].details = ApproachDetailsArray;
      } else if (ele.type === PROFILE_DESCRIPTION_TYPES.WHAT_TO_EXPECT) {
        tmp.description[index].details = expectDetailsArray;
      }
    });

    const userRef = doc(firestore, CollectionNames.USERS, user.uid);
    await setDoc(userRef, { coachData: { ...tmp } }, { merge: true })
      .then((value) => {
        toast.success("Updated", {});
        setLoader(false);
      })
      .catch((e) => {
        console.error("error", e);
        setLoader(false);
      });
  };

  const profilePanel = (
    id: number,
    name: string,
    type: PROFILE_DESCRIPTION_TYPES
  ) => {
    return (
      <div className="flex-1 bg-EGGWHITE m-5">
        <div className="flex flex-row  justify-between mb-5">
          <div className="flex flex-col">
            <Stack direction="row" spacing={1}>
              <p className="mt-2">{name}</p>
              <div className="">
                <IconButton
                  onClick={() => {
                    switch (type) {
                      case PROFILE_DESCRIPTION_TYPES.MY_APPROACH:
                        setApproach({
                          text: approachDescription.text,
                          edit: !approachDescription.edit,
                        });
                        break;
                      case PROFILE_DESCRIPTION_TYPES.ABOUT:
                        setAbout({
                          text: aboutDescription.text,
                          edit: !aboutDescription.edit,
                        });
                        break;
                      case PROFILE_DESCRIPTION_TYPES.WHAT_TO_EXPECT:
                        setExpect({
                          text: expectDescription.text,
                          edit: !expectDescription.edit,
                        });
                        break;
                      default:
                    }
                  }}
                  aria-label="edit"
                >
                  <ModeEditOutlineOutlinedIcon
                    sx={{ color: "#FF5E00", fontSize: 25 }}
                  />
                </IconButton>
              </div>
            </Stack>
          </div>
          <div className="flex flex-col">
            <div className="mt-2">
              {id === 1 ? (
                <LanguageToggler
                  value={language}
                  onChageHandler={langugeHandler}
                />
              ) : null}
            </div>
          </div>
        </div>
        <TextField
          disabled={
            type === PROFILE_DESCRIPTION_TYPES.MY_APPROACH
              ? !approachDescription.edit
              : type === PROFILE_DESCRIPTION_TYPES.ABOUT
              ? !aboutDescription.edit
              : type === PROFILE_DESCRIPTION_TYPES.WHAT_TO_EXPECT
              ? !expectDescription.edit
              : true
          }
          onChange={(e) => setFieldValue(e.target.value, type)}
          className={"rounded-lg"}
          style={{
            color: "#000",
            backgroundColor: "#FFFF",
          }}
          value={
            type === PROFILE_DESCRIPTION_TYPES.ABOUT
              ? aboutDescription.text[language]
              : type === PROFILE_DESCRIPTION_TYPES.WHAT_TO_EXPECT
              ? expectDescription.text[language]
              : type === PROFILE_DESCRIPTION_TYPES.MY_APPROACH
              ? approachDescription.text[language]
              : ""
          }
          placeholder={name}
          variant="outlined"
          size="small"
          multiline
          color="warning"
          rows={10}
          fullWidth
        />
      </div>
    );
  };

  return (
    <Fragment>
      <List sx={{ width: "100%", bgcolor: "#FBF8F7" }}>
        {inputs.map((value) => (
          <ListItem key={value.id} disableGutters>
            {profilePanel(value.id, value.name, value.type)}
          </ListItem>
        ))}
        <div className="flex flex-1 m-auto w-3/12 py-5">
          <Button
            onClick={() => {
              setDetailsToDB();
            }}
            fullWidth={true}
            style={{
              borderRadius: 35,
              backgroundColor: "#FF5E00",
              fontSize: "12px",
              fontWeight: "bold",
            }}
            variant="contained"
          >
            {loader ? <CircularProgress color="inherit" size={20} /> : "Save"}
          </Button>
        </div>
      </List>
    </Fragment>
  );
};

export default ExpertProfile;
