import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AgoraUIKit, { PropsInterface } from "agora-react-uikit";
import MicIcon from "../../assets/mic.svg";
import MicOffIcon from "../../assets/mic-off.svg";
import VideoIcon from "../../assets/video.svg";
import VideoOffIcon from "../../assets/video-off.svg";
import EndCallIcon from "../../assets/phone-off.svg";

const MeetingPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // get eventID
  const eventID = location.state?.eventID;

  useEffect(() => {
    if (!location?.state?.eventID) {
      navigate("/dashboard");
    } else {
      setVideoCall(true);
    }
  }, [navigate, location]);

  const [videoCall, setVideoCall] = useState(false);

  const agoraProps: PropsInterface = {
    rtcProps: {
      appId: process.env.REACT_APP_AGORA_APP_ID || "",
      channel: eventID,

      token: null,
    },
    callbacks: {
      EndCall: () => {
        setVideoCall(false);
        navigate("/dashboard");
      },
    },
  };

  return videoCall ? (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        position: "fixed",
        top: "0px",
        left: "0px",
        flex: 1,
        zIndex: 4000,
        backgroundColor: "#007bff22",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <AgoraUIKit
          rtcProps={agoraProps.rtcProps}
          callbacks={agoraProps.callbacks}
          styleProps={{
            customIcon: {
              mic: MicIcon,
              micOff: MicOffIcon,
              videocam: VideoIcon,
              videocamOff: VideoOffIcon,
              callEnd: EndCallIcon,
            },

            maxViewContainer: {
              backgroundColor: "#F6D7D8",
            },
            maxViewStyles: {
              backgroundColor: "#F6D7D8",
            },
            pinnedVideoContainer: {
              backgroundColor: "#3A2E29",
            },
            localBtnContainer: {
              backgroundColor: "#3A2E29",
              justifyContent: "center",
              gap: 40,
              height: 100,
            },
            localBtnStyles: {
              endCall: {
                backgroundColor: "#FF5E00",
                borderColor: "#FF5E00",
                width: 65,
                height: 65,
              },
              muteLocalAudio: {
                backgroundColor: "#FF9985",
                borderColor: "#FF9985",
                width: 50,
                height: 50,
              },
              muteLocalVideo: {
                backgroundColor: "#FF9985",
                borderColor: "#FF9985",
                width: 50,
                height: 50,
              },
            },
          }}
        />
      </div>
    </div>
  ) : (
    <div>{eventID}</div>
  );
};

export default MeetingPage;
