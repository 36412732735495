import {
  DocumentData,
  FirestoreDataConverter,
  Timestamp,
} from "@firebase/firestore";

export enum PROFILE_DESCRIPTION_TYPES {
  ABOUT = "about",
  MY_APPROACH = "my_approach",
  WHAT_TO_EXPECT = "what_to_expect",
}

export enum LANGUAGES {
  ENGLISH = "en",
  SWEDISH = "sv",
}

export interface IUSERDATA {
  ID: string | null;
  name: string | null;
  type: string | null;
  photoURL: string | null;
}

export interface IEVENTS {
  dateTime: Timestamp;
  status: string | null;
  userData: IUSERDATA[] | null;
  usersList: string[] | null;
  eventID: string;
}

export type TTIMESLOT = {
  id: string | number;
  time: string;
  checked?: boolean;
  realTime?: string;
};

export interface IUSER {
  userID: string | null;
  userType: string | null;
  topics: [];
  photoURL: string | null;
  onboarded: boolean | null;
  name: string | null;
  firstName: string | null;
  lastName: string | null;
  myStory: string | null;
  lang: string | null;
  isCoach: boolean | null;
  empCode: string | null;
  diagnoses: [];
  country: string | null;
  city: string | null;
  age: string | null;
  coachData: ICOACHDATA | null;
}

export interface ICOACHDATA {
  whattoexpect?: IWHATTOEXPECT;
  approach?: IAPPROACH;
  about?: IABOUT;
  description?: IDESCRIPTION[] | null;
  experties: {
    en: string | null;
    sv: string | null;
  };
}

interface IAPPROACH {
  approach: TDETAILS[];
}
interface IWHATTOEXPECT {
  whattoexpect: TDETAILS[];
}
interface IABOUT {
  about: TDETAILS[];
}

export type TDETAILS = {
  en: string | null;
  sv: string | null;
};

export interface IDESCRIPTION {
  details: TDETAILS[];
  title: {
    en: string | null;
    sv: string | null;
  };
  type: PROFILE_DESCRIPTION_TYPES;
}

export interface IDESCRIPTION {
  details: TDETAILS[];
  title: {
    en: string | null;
    sv: string | null;
  };
  type: PROFILE_DESCRIPTION_TYPES;
}

export enum EventStatus {
  PENDING = "pending",
  STARTED = "started",
  ENDED = "ended",
}
