import { FC } from "react";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import SwedishFlag from "../../assets/sweden.png";
import EnglishFlag from "../../assets/united-kingdom.png";
import { LANGUAGES } from "../../util/types";

type Props = {
  value: "en" | "sv";
  onChageHandler: (e: LANGUAGES) => void;
};
const LanguageToggler: FC<Props> = ({ value, onChageHandler }) => {
  const avatarStyle = {
    width: 25,
    height: 25,
  };

  return (
    <Stack direction="row" spacing={1}>
      <div
        onClick={() => onChageHandler(LANGUAGES.SWEDISH)}
        className="cursor-pointer hover:opacity-75"
      >
        <Avatar
          sx={[
            avatarStyle,
            {
              border:
                value === LANGUAGES.SWEDISH ? "2px solid #FF5E00" : "none",
            },
          ]}
          alt="SV"
        >
          <img src={SwedishFlag} alt="Sv" />
        </Avatar>
      </div>
      <div
        onClick={() => onChageHandler(LANGUAGES.ENGLISH)}
        className="cursor-pointer hover:opacity-75"
      >
        <Avatar
          sx={[
            avatarStyle,
            {
              border:
                value === LANGUAGES.ENGLISH ? "2px solid #FF5E00" : "none",
            },
          ]}
          alt="EN"
        >
          <img src={EnglishFlag} alt="EN" />
        </Avatar>
      </div>
    </Stack>
  );
};

export default LanguageToggler;
