interface CustomInputProps {
  placeholder: string;
  type: string;
  name: string;
  icon?: any;
  value: string;
  onChange: (e: any) => void;
  required: boolean;
}

const CustomInput = (props: CustomInputProps) => {
  const { placeholder, type, name, icon, value, onChange, required } = props;
  return (
    <>
      <div className="bg-gray-100 border-2 border rounded-3xl  w-80 p-1 flex items-center mb-3 border-transparent">
        {icon || null}
        <input
          onChange={onChange}
          type={type}
          required={required}
          value={value}
          name={name}
          placeholder={placeholder}
          className="bg-gray-100 outline-none text-sm flex-1 
   placeholder-PEACH
   text-PEACH"
        />
      </div>
    </>
  );
};

export default CustomInput;
