import { useEffect } from "react";
import Logo from "../../assets/bumpy-logo.png";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 2000);
  }, [navigate]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <main className="flex flex-col items-center justify-items-start w-full flex-1 py-20 px-20 text-center bg-ALABASTER">
        <div className="lg:w-2/12 sm:w-6/12">
          <img src={Logo} alt="Logo" />
          <div className="w-full">
            <div className="py-10">
              <h2 className="text-2xl font-bold text-CHARCOAL ">{`Page not Found !`}</h2>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default NotFoundPage;
