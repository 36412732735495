import { Backdrop, CircularProgress } from "@mui/material";
import Logo from "../../assets/bumpy-logo.png";

const LoadingPage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <main className="flex flex-col items-center justify-items-start w-full flex-1 py-20 px-20 text-center bg-ALABASTER">
        <div className="lg:w-2/12 sm:w-6/12">
          <img src={Logo} alt="Logo" />
        </div>
        <Backdrop style={{ zIndex: 2, color: "#fff" }} open invisible={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </main>
    </div>
  );
};
export default LoadingPage;
