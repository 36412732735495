import { useEffect, useLayoutEffect, useState } from "react";
import { EventStatus, IEVENTS } from "../../util/types";
import moment from "moment";
import { ListItem, Card, CardHeader, Avatar, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import JoinCall from "../../assets/Join-Call.png";
import DisableCall from "../../assets/Join-Call-Disable.png";
import { useNavigate } from "react-router-dom";
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";
import { auth, firestore } from "../../fbConfig";
import { CollectionNames } from "../../util/collections";

const SessionTab = () => {
  const navigate = useNavigate();
  const [todayEvents, setTodayEvents] = useState<IEVENTS[]>([]);
  const [upComingEvents, setUpcomingEvents] = useState<IEVENTS[]>([]);

  useEffect(() => {
    getEvents();
  }, []);

  const getEvents = async () => {
    const todaysEventData: IEVENTS[] = [];
    const upcomingEventData: IEVENTS[] = [];
    try {
      const collectionRef = query(
        collection(firestore, CollectionNames.EVENTS),
        where("usersList", "array-contains-any", [auth.currentUser?.uid]),
        where("status", "!=", EventStatus.ENDED),
        orderBy("status"),
        orderBy("dateTime")
      );
      await getDocs(collectionRef).then((querySnapshot) => {
        querySnapshot.forEach((data) => {
          const event_date = moment(data.data()?.dateTime.toDate()).format(
            "YYYY-MM-DD"
          );
          if (moment(event_date).isAfter(todayFormatedYYYY_MM_DD)) {
            upcomingEventData.push({
              ...(data.data() as IEVENTS),
              eventID: data.id,
            });
          } else {
            todaysEventData.push({
              ...(data.data() as IEVENTS),
              eventID: data.id,
            });
          }
        });

        setUpcomingEvents(upcomingEventData);
        setTodayEvents(todaysEventData);
      });
    } catch (err) {
      console.error("Failed to load events ", err);
    }
  };

  const todayFormatedYYYY_MM_DD = moment().format("YYYY-MM-DD");

  const eventCard = (userDTO: IEVENTS, upcoming: boolean) => {
    const patientName = userDTO?.userData?.find(
      (element) => element.type === "patient"
    );
    return (
      <Card
        sx={{
          borderRadius: 5,
          minWidth: 320,
          maxWidth: 320,
          backgroundColor: upcoming ? "#F5EAE5" : "#FF9985",
        }}
      >
        <CardHeader
          avatar={
            patientName?.photoURL ? (
              <Avatar
                alt="Remy Sharp"
                src={patientName?.photoURL}
                sx={{ width: 50, height: 50 }}
              />
            ) : (
              <Avatar sx={{ bgcolor: "red" }} aria-label="recipe">
                {patientName?.name || ""}
              </Avatar>
            )
          }
          action={
            <IconButton
              disabled={upcoming ? true : false}
              onClick={() => {
                navigate("/meeting", {
                  state: {
                    eventID: userDTO.eventID,
                  },
                });
              }}
              aria-label="settings"
            >
              <img
                width={40}
                height={40}
                src={upcoming ? DisableCall : JoinCall}
                alt="Call"
              />
            </IconButton>
          }
          title={`Session with ${patientName?.name}`}
          subheader={
            <>
              <Typography style={{ fontWeight: "bold" }}>
                {`
      ${moment(userDTO?.dateTime.seconds * 1000, "x").format("MMMM DD HH:mm")} 
        `}
              </Typography>
              {upcoming ? (
                <button onClick={async () => {}}>
                  <p style={{ color: "#fff" }}>Cancel session</p>
                </button>
              ) : null}
            </>
          }
        />
      </Card>
    );
  };

  return (
    <div className="flex justify-start items-start bg-EGGWHITE p-5">
      {todayEvents.length > 0 && (
        <>
          <div className="flex flex-col items-start justify-start">
            <div className="pb-3">
              <p className="text-CHARCOAL">Today</p>
            </div>
            <div>
              {todayEvents.map((value, index) => (
                <ListItem key={index} disableGutters>
                  {eventCard(value, false)}
                </ListItem>
              ))}
            </div>
          </div>
          <div className="mx-20" />
        </>
      )}

      {upComingEvents.length > 0 && (
        <div className="flex flex-col justify-start items-start">
          <div className="pb-3 ">
            <p className="text-CHARCOAL">Upcoming</p>
          </div>
          <div>
            {upComingEvents.map((value, index) => (
              <ListItem key={index} disableGutters>
                {eventCard(value, true)}
              </ListItem>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SessionTab;
