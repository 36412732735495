import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import LogoutIcon from "@mui/icons-material/Logout";
import Logo from "../../assets/bumpy-logo.png";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ExpertProfile from "../../components/ExpertProfile";
import CalendarTab from "../../components/CalendarTab";
import SessionTab from "../../components/Sesstion";
import { useAuthState } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

const drawerWidth = 300;

interface Props {
  window?: () => Window;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const DashboardPage = (props: Props) => {
  const navigate = useNavigate();
  const { logout, user } = useAuthState();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const drawer = (
    <div>
      <Toolbar />

      <div className="mx-5">
        <div>
          <div
            onClick={async () => {
              await logout();
              navigate("/");
            }}
            className="flex mb-2 justify-start items-start p-2 group cursor-pointer m-auto"
          >
            <LogoutIcon className="text-2xl text-CHARCOAL group-hover:text-TANGERINE" />
            <h3 className="text-base text-gray-800 group-hover:text-TANGERINE font-semibold pl-2">
              Logout
            </h3>
          </div>
        </div>
        <div className="flex flex-col justify-start item-center">
          <div className=" my-4 border-gray-100 pb-4">
            <div className="mb-5 ml-5 justify-start items-center lg:w-40">
              <img src={Logo} alt="Logo" />
            </div>
            {/* profile  */}
            <div className=" flex-col bg-white rounded-xl flex w-4/5 h-44 py-10 items-center justify-center">
              <div
                style={{ width: "100%", height: "100%", position: "relative" }}
              >
                {user?.photoURL ? (
                  <img
                    style={{
                      objectFit: "contain",
                      height: "100%",
                      width: "100%",
                    }}
                    src={user?.photoURL}
                    alt="User"
                  />
                ) : null}
              </div>

              <div className="px-1 py-2" />
              <h3 className="text-base text-gray-800 font-bold">
                {`  ${user?.firstName} ${user?.lastName}`}
              </h3>
              <h3 className="text-base text-TANGERINE">
                {`  ${user?.coachData?.experties?.en}`}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          boxShadow: "none",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar className="bg-SNOWWHITE">
          <IconButton
            color="default"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div">
            <h3 className="text-CHARCOAL py-3">
              Hello<span className="font-bold"> {user?.firstName}! </span>{" "}
              Welcome to your page
            </h3>
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "#F4E9E4",
              borderWidth: 0,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "#F4E9E4",
              borderWidth: 0,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          backgroundColor: "#FFFFFF",
        }}
      >
        <Toolbar />

        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            TabIndicatorProps={{
              style: {
                backgroundColor: "#FF5E00",
                color: "#FF5E00",
              },
            }}
          >
            <Tab
              label="MY SESSIONS"
              {...a11yProps(0)}
              disableRipple={true}
              style={{ color: "#3A2E29", fontWeight: "bold" }}
            />
            <Tab
              label="MY CALENDAR"
              {...a11yProps(1)}
              disableRipple={true}
              style={{ color: "#3A2E29", fontWeight: "bold" }}
            />
            <Tab
              label="MY PROFILE"
              {...a11yProps(2)}
              disableRipple={true}
              style={{ color: "#3A2E29", fontWeight: "bold" }}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <SessionTab />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CalendarTab />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ExpertProfile />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default DashboardPage;
