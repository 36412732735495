import { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import Logo from "../../assets/bumpy-logo.png";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import CustomInput from "../../components/CustomInput/CustomInput";
import { Link } from "react-router-dom";
import { useAuthState } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

const Loginpage = () => {
  const { login, user, loginError } = useAuthState();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
  }, [navigate, user]);

  const [values, setValues]: any = useState({
    email: "",
    password: "",
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      await login(values.email, values.password);
    } catch (err) {
      console.error("Error Occured ", err);
    }
  };

  const onChange = (e: any) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const inputs = [
    {
      id: 1,
      name: "email",
      type: "email",
      placeholder: "Your email",
      errorMessage: "It should be a valid email address!",
      required: true,
      icon: <MailOutlineIcon className="text-PEACH m-2" fontSize="small" />,
    },
    {
      id: 2,
      name: "password",
      type: "password",
      placeholder: "Password",
      errorMessage:
        "Password should be 8-20 characters and include at least 1 letter, 1 number and 1 special character!",
      pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`,
      required: true,
      icon: <LockOutlinedIcon className="text-PEACH m-2" fontSize="small" />,
    },
  ];

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <main className="flex flex-col items-center justify-items-start w-full flex-1 py-20 px-20 text-center bg-ALABASTER">
        <div className="absolute top-7 left-0">
          <a href="https://www.bumpyapp.se/">
            <IconButton aria-label="bumpy_web">
              <ArrowBack sx={{ fontSize: 25 }} />
            </IconButton>
          </a>
        </div>
        <div className="lg:w-2/12 sm:w-6/12">
          <img src={Logo} alt="Logo" />
        </div>
        <div className="w-full">
          <div className="py-10">
            <h2 className="text-2xl font-bold text-CHARCOAL ">{`Expert Login`}</h2>
            {loginError ? (
              <div className="pt-5">
                <h3 className="text-sm font-bold text-TANGERINE">
                  {"Invalid Email Or Password"}
                </h3>
              </div>
            ) : null}
          </div>
          <div className="flex flex-col items-center justify-center">
            <form onSubmit={handleSubmit}>
              {inputs.map((input, index) => (
                <div key={index}>
                  <CustomInput
                    key={input.id}
                    {...input}
                    value={values[input.name]}
                    placeholder={input.placeholder}
                    type={input.type}
                    name={input.name}
                    icon={input.icon}
                    onChange={onChange}
                    required={input.required}
                  />
                </div>
              ))}
              <button className=" w-80 bg-TANGERINE hover:opacity-75 text-white font-bold py-2 px-4 rounded-full">
                SIGN IN
              </button>
            </form>

            <div className="py-3">
              <Link to="/forgotpassword">
                <div className="text-TANGERINE">Forgot Password</div>
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Loginpage;
