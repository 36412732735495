import { createContext, useContext, useEffect, useState } from "react";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { auth } from "../fbConfig";
import { IUSER } from "../util/types";
import { getCoachDetails } from "../util/services/auth";

const AuthContext = createContext<any>(null);

export const AuthContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [loginError, setLoginError] = useState<boolean>(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        await setCoach(user.uid);
      } else {
        setLoading(false);
        setUser(null);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const getCoach = async (uid: string) => {
    const result: IUSER | null = await getCoachDetails({ uid: uid });
    return result;
  };

  const login = async (email: string, password: string) => {
    setLoading(true);
    return await signInWithEmailAndPassword(auth, email, password)
      .then((value) => {})
      .catch((error) => {
        setLoading(false);
        setUser(null);
        setLoginError(true);
      });
  };

  const setCoach = async (uid: string) => {
    await getCoach(uid)
      .then(async (userDto: IUSER | null) => {
        if (userDto?.isCoach) {
          const user_obj = {
            uid: userDto?.userID,
            name: userDto?.name,
            firstName: userDto?.firstName,
            lastName: userDto?.lastName,
            isCoach: userDto?.isCoach,
            userType: userDto?.userType,
            topics: userDto?.topics,
            photoURL: userDto?.photoURL,
            onboarded: userDto?.onboarded,
            myStory: userDto?.myStory,
            lang: userDto?.lang,
            empCode: userDto?.empCode,
            diagnoses: userDto?.diagnoses,
            country: userDto?.country,
            city: userDto?.city,
            age: userDto?.age,
            coachData: userDto?.coachData,
          };
          setUser(user_obj);
          setLoginError(false);
          setLoading(false);
        } else {
          setUser(null);
          setLoading(false);
          setLoginError(true);
          await logout(true);
        }
      })
      .catch((e) => {
        setLoading(false);
        setLoginError(false);
      });
  };

  const logout = async (showErrMsg = false) => {
    setLoading(true);
    await signOut(auth)
      .then(() => {
        setUser(null);
        setLoading(false);
        if (showErrMsg) {
          setLoginError(true);
        } else {
          setLoginError(false);
        }
      })
      .catch((error) => console.error("logout failed", error));
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        loading,
        setLoading,
        setUser,
        loginError,
        setLoginError,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthState = () => useContext(AuthContext);
