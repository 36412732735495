import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Loginpage from "./pages/login";
import DashboardPage from "./pages/dashboard";
import NotFoundPage from "./pages/404";
import { useAuthState } from "./context/AuthContext";
import LoadingPage from "./pages/Loading";
import MeetingPage from "./pages/meeting";

const App = () => {
  const { loading } = useAuthState();

  const AuthenticatedRoute = ({ children }: { children: JSX.Element }) => {
    const { user } = useAuthState();
    return user ? children : <Navigate to="/" />;
  };

  return (
    <Router>
      {!loading ? (
        <Routes>
          <Route path="/" element={<Loginpage />} />
          <Route
            path="/dashboard"
            element={
              <AuthenticatedRoute>
                <DashboardPage />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/meeting"
            element={
              <AuthenticatedRoute>
                <MeetingPage />
              </AuthenticatedRoute>
            }
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      ) : (
        <LoadingPage />
      )}
    </Router>
  );
};

export default App;
