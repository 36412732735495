export enum CollectionNames {
  USERS = "users",
  COACHES = "coaches",
  AVAILABLE_TIME = "availableTime",
  EVENTS = "events",
  CLINIC_REVIEW = "clinicReview",
  USER_REVIEW = "userReview",
  COACHING_SESSION_USERS = "coachingSessionUsers",
  GROUPS = "groups",
  POSTS = "posts",
  QUESTIONS = "questions",
  USERS_POSTS = "userPosts",
  NEW_FEEDS = "new_feeds",
  FAQ = "FAQ",
  KNOWLEDGEBASE = "knowledgeBase",
  ARTICLES = "articles",
  FCM_TOKENS = "fcmTokens",
  APP_VERSION = "appVersion",
  CHAT_NOTIFICATION = "chatNotifications",
  NOTIFICATION = "notifications",
}

export enum CloudFunctionsEnum {
  CHECKEMPCODE = "checkEmployeCode",
  REQUESTRTCTOKEN = "requestRtcToken",
  SENDFORGOTPASSWORDEMAIL = "sendForgotPasswordEmail",
}
