import { collection, doc, Timestamp, setDoc, getDoc } from "firebase/firestore";
import { firestore } from "../../fbConfig";
import { CollectionNames } from "../collections";
import moment from "moment";

// const getCoachAvailbleTimeSolts = async (
//   coachId: string,
//   fromDate: number,
//   toDate: number
// ) => {
//   const timeSlotData: any[] = [];
//   const collectionRef = query(
//     collection(
//       firestore,
//       CollectionEnum.COACHES,
//       coachId,
//       CollectionEnum.AVAILABLE_TIME
//     ),
//     where("date", ">=", fromDate),
//     where("date", "<=", toDate),
//     orderBy("date")
//   );
//   return await getDocs(collectionRef)
//     .then((querySnapshot) => {
//       querySnapshot.docs.map((doc) => {
//         timeSlotData.push(doc.data() as IAVAILABLETIME);
//       });
//       return timeSlotData;
//     })
//     .catch((error) => {
//       console.error("Error ", error);
//       return timeSlotData;
//     });
// };

export interface ITIMESLOT {
  active: boolean | null;
  time: Timestamp;
}

export const getTimeSlotsForTheDay = async (
  coachId: string,
  selectedDate: Date
): Promise<ITIMESLOT[] | []> => {
  const collectionRef = doc(
    collection(
      firestore,
      CollectionNames.COACHES,
      coachId,
      CollectionNames.AVAILABLE_TIME
    ),
    moment(selectedDate)?.format("YYYY-MM-DD")
  );
  return await getDoc(collectionRef)
    .then((documentSnapshot) => {
      return (documentSnapshot.data()?.timeSlots as ITIMESLOT[]) || [];
    })
    .catch((error) => {
      console.error("Error ", error);
      return [];
    });
};

export default interface IAVAILABLETIME {
  date: number | null;
  timeSlots: ITIMESLOT[] | null;
  id?: string;
}

export const setAvailableDateTime = async (
  payload: IAVAILABLETIME,
  coachId: string
) => {
  const docRef = doc(
    collection(
      firestore,
      CollectionNames.COACHES,
      coachId,
      CollectionNames.AVAILABLE_TIME
    ),
    payload.id
  );
  await setDoc(docRef, payload, { merge: true })
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error("Error", error);
      return false;
    });
};

// export const cancelEvent = async (eventID: string) => {
//   await deleteDoc(doc(firestore, CollectionEnum.EVENTS, eventID));
// };
