import {
  Backdrop,
  Checkbox,
  CircularProgress,
  FormControlLabel,
} from "@mui/material";
import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import moment from "moment";
import "react-calendar/dist/Calendar.css";
import { TTIMESLOT } from "../../util/types";
import { useAuthState } from "../../context/AuthContext";
import {
  getTimeSlotsForTheDay,
  setAvailableDateTime,
} from "../../util/services/session";
import { toast } from "react-toastify";

const CalendarTab = () => {
  const { user } = useAuthState();

  let defaultTimeSlots = [
    {
      id: 1,
      time: "08:00",
      checked: false,
      realTime: "08",
    },
    {
      id: 2,
      time: "09:00",
      checked: false,
      realTime: "09",
    },
    {
      id: 3,
      time: "10:00",
      realTime: "10",
      checked: false,
    },
    {
      id: 4,
      time: "11:00",
      checked: false,
      realTime: "11",
    },
    {
      id: 5,
      time: "12:00",
      checked: false,
      realTime: "12",
    },
    {
      id: 6,
      time: "13:00",
      checked: false,
      realTime: "13",
    },
    {
      id: 7,
      time: "14:00",
      realTime: "14",
      checked: false,
    },
    {
      id: 8,
      time: "15:00",
      checked: false,
      realTime: "15",
    },
    {
      id: 9,
      time: "16:00",
      checked: false,
      realTime: "16",
    },
    {
      id: 10,
      time: "17:00",
      checked: false,
      realTime: "17",
    },
    {
      id: 11,
      time: "18:00",
      realTime: "18",
      checked: false,
    },
    {
      id: 12,
      time: "19:00",
      realTime: "19",
      checked: false,
    },
  ];

  const [calendarLoader, setCalendarLoader] = useState(false);
  const [coachTimeSlots, setCoachTimeSlots] = useState<TTIMESLOT[] | []>([
    ...defaultTimeSlots,
  ]);
  const [activeDate, setActiveDate] = useState(
    moment().format("dddd MMMM Do YYYY")
  );

  useEffect(() => {
    fetchTimeSlots();
  }, [activeDate]);

  const fetchTimeSlots = async () => {
    setCalendarLoader(true);
    const AvailbleTimeSlots = await getTimeSlotsForTheDay(
      user.uid,
      new Date(moment(activeDate, "dddd MMMM Do YYYY").format("YYYY-MM-DD"))
    );

    if (AvailbleTimeSlots.length > 0) {
      AvailbleTimeSlots.map((t) => {
        let time = moment(new Date(t.time.seconds * 1000)).format("HH:mm");

        const tmpTimeSlots = [...defaultTimeSlots];

        tmpTimeSlots.map((ele, index) => {
          if (ele.time === time) {
            tmpTimeSlots[index].checked = true;
          }
        });

        setCoachTimeSlots([...tmpTimeSlots]);
      });
    } else {
      setCoachTimeSlots([...defaultTimeSlots]);
    }

    setCalendarLoader(false);
  };

  const tileDisabled = ({ activeStartDate, date, view }: any) => {
    let isBefore = moment(moment(date).format("YYYY-MM-DD")).isBefore(
      moment().format("YYYY-MM-DD"),
      "day"
    );
    return isBefore;
  };

  const calendarComponent = () => {
    return (
      <div>
        <Calendar
          tileDisabled={tileDisabled}
          onChange={onChange}
          value={new Date()}
        />
      </div>
    );
  };

  const onCancel = () => {
    setCoachTimeSlots([...defaultTimeSlots]);
  };

  const onChange = (e: any) => {
    setActiveDate(moment(e.toString()).format("dddd MMMM Do YYYY"));
  };

  const checkBoxChange = (value: boolean, id: any) => {
    const tmpcoachTimeSlots = [...coachTimeSlots];
    tmpcoachTimeSlots[id - 1].checked = value;

    setCoachTimeSlots([...tmpcoachTimeSlots]);
  };

  const onConfirm = async () => {
    setCalendarLoader(true);
    const timeslot_array: any[] = [];
    coachTimeSlots.forEach((tme) => {
      if (tme.checked && tme.realTime) {
        const get_year = moment(activeDate, "dddd MMMM Do YYYY").format("YYYY");
        const get_month = moment(activeDate, "dddd MMMM Do YYYY").format("MM");
        const get_date = moment(activeDate, "dddd MMMM Do YYYY").format("DD");

        const date_timestamp = new Date(
          +get_year,
          +get_month - 1,
          +get_date,
          +tme.realTime,
          +"00"
        );
        const timeslotPayload = {
          active: true,
          time: date_timestamp,
        };
        timeslot_array.push(timeslotPayload);
      }
    });
    const payload = {
      id: moment(activeDate, "dddd MMMM Do YYYY").format("YYYY-MM-DD"),
      date: parseInt(
        moment(activeDate, "dddd MMMM Do YYYY").format("YYYYMMDD")
      ),
      timeSlots: timeslot_array,
    };

    await setAvailableDateTime(payload, user.uid).then((value) => {
      setCalendarLoader(false);
      toast.success("Updated", {});
    });
  };

  return (
    <div className="flex justify-start ">
      {calendarLoader && (
        <Backdrop style={{ zIndex: 2, color: "#fff" }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div className="pb-3">
        <p className="text-CHARCOAL">{`Select Date`}</p>
        <div className="pt-5">{calendarComponent()}</div>
      </div>
      <div className="flex flex-col justify-start px-20 py-10 w-full">
        <p className="text-CHARCOAL">Add / remove availability</p>
        <p className="text-CHARCOAL font-bold">{activeDate}</p>
        <div className="flex my-1.5 justify-start items-start">
          <div className="flex flex-col grid grid-cols-2 gap-2">
            {coachTimeSlots.map((e, index) => (
              <div className="flex flex-col flex-wrap overflow-aut" key={index}>
                <FormControlLabel
                  key={e.id}
                  control={
                    <Checkbox
                      sx={{
                        color: "#FF5E00",
                        "&.Mui-checked": {
                          color: "#FF5E00",
                        },
                      }}
                      checked={e.checked}
                      onChange={(event) =>
                        checkBoxChange(event.target.checked, e.id)
                      }
                    />
                  }
                  label={e.time}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="flex flex-col my-1.5 w-3/6">
          <button
            onClick={onConfirm}
            className="w-55 bg-TANGERINE  hover:opacity-75 text-white py-2 px-4 rounded-full font-bold"
          >
            CONFIRM
          </button>
        </div>

        <div className="flex flex-col my-1.5 w-3/6">
          <button
            onClick={onCancel}
            className="w-55 bg-SNOWWHITE text-TANGERINE py-2 px-4 border-TANGERINE border-2 rounded-full font-bold  hover:opacity-75"
          >
            CANCEL
          </button>
        </div>
      </div>
    </div>
  );
};

export default CalendarTab;
